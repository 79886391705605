import axios from "axios";
import { server } from "../config/api"; // Importando a URL do config.js

const api = axios.create({
  //baseURL: "https://lucin-api.salesbreath.com.br/api/v1",
  //baseURL: "http://lucin2homologacao.salesbreath.com.br:3010/api/v1/",
  baseURL: server,
  headers: { "Content-Type": "application/json" }
});

export default api;
